/**
 * Removes `null` and `undefined` properties from an object
 *
 * @see https://stackoverflow.com/a/57625661
 * @param {Object} obj
 * @return {Object}
 */
export function cleanObject(obj) {
  return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
}

/**
 * Forces a download in the browser
 *
 * @param {String} fileName File name
 * @param {String} data
 */
export function downloadFile(fileName, data) {
  let tempLink = document.createElement('a')
  tempLink.href = window.URL.createObjectURL(new Blob([data]))
  tempLink.setAttribute('download', fileName)
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
}
