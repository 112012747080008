<template>
  <span>
    <v-icon
      v-if="status === true"
      color="green"
    >
      mdi-check-circle-outline
    </v-icon>
    <v-icon
      v-else-if="status === false"
      color="red"
    >
      mdi-close-circle-outline
    </v-icon>
    <v-icon
      v-else
      color="grey"
    >
      mdi-checkbox-blank-circle-outline
    </v-icon>
  </span>
</template>

<script>
export default {
  name: 'ActiveBadge',
  props: {
    status: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
}
</script>
